import { createStore } from "vuex";

// Create a new store instance.
export const store = createStore({
    strict: process.env.NODE_ENV !== "production",
    state ()
    {
        return {
            user: null
        };
    },
    getters: {
        user (state)
        {
            return state.user;
        }
    },
    mutations: {
        SET_USER (state, data)
        {
            state.user = data;
        }
    },
    actions: {
        fetchUser ({ commit }, user)
        {
            if (user)

                commit("SET_USER", user);

            else

                commit("SET_USER", null);
        }
    }
});
