import mixpanel, { Dict } from "mixpanel-browser";
import { LocationQuery, RouteLocationNormalized } from "vue-router";

/**
 * Tracked events and properties on the client:
 *
 * Page View
 * - view_path: The current page path, with the format "/page-name/sub-page/"
 * - view_hash: The current hash, if any: "hash-name"
 * - view_query_*KEY*: The current query values: "vew_query_key"
 *
 */

/**
 *
 * @param env
 */
export function init (env: any)
{
    const mixpanelProductionId = "811273e56a321c4e6a089c77d2c10467";
    const mixpanelProductionConfig = {
        ignore_dnt: true,
        api_host: "https://api-eu.mixpanel.com",
        opt_out_tracking_by_default: true
    };

    const mixpanelStagingId = "b54695389f34c95c9239425986013beb";
    const mixpanelStagingConfig = {
        debug: true,
        ignore_dnt: true,
        api_host: "https://api-eu.mixpanel.com",
        opt_out_tracking_by_default: true
    };

    if (env.mode == "production")

        mixpanel.init(mixpanelProductionId, mixpanelProductionConfig);

    else

        mixpanel.init(mixpanelStagingId, mixpanelStagingConfig);

    mixpanel.register_once({
        environment: env.mode
    });
}

/**
 * Set the consent of the user to opt-in or opt-out of analytics.
 * @param optIn
 */
export function setOptIn (optIn: boolean)
{
    if (optIn)
    {
        if (!mixpanel.has_opted_in_tracking())

            mixpanel.opt_in_tracking();
    }
    else
    {
        mixpanel.opt_out_tracking();
    }
}

/**
 * Set the user ID of the signed-in user.
 * @param userId
 */
export function setUser (userId: string)
{
    if (userId)
    {
        // Identify the user with their account ID.
        mixpanel.identify(userId);
    }
}

export function setPropertyOnce (name: string, value: string)
{
    const props = <Dict>{};
    props[name] = value;
    mixpanel.register_once(props);
}

export function setProperty (name: string, value: string)
{
    const props = <Dict>{};
    props[name] = value;
    mixpanel.register(props);
}

/**
 * Track a page view
 * @param route - The current route
 */
export function trackPageView (route: RouteLocationNormalized)
{
    const properties: Record<string, string> = {};
    addPath(properties, sanitizePath(route.path));
    addHash(properties, sanitizeHash(route.hash));
    addQuery(properties, route.query);
    if (properties.view_path)

        mixpanel.track("Page View", properties);

    else

        console.warn("Invalid page view");
}

export function track (name: string, properties: Dict)
{
    mixpanel.track(name, properties);
}

function sanitizePath (path: string)
{
    if (!path)

        return "";

    if (!path.endsWith("/"))

        path += "/";

    return path;
}

function sanitizeHash (hash: string)
{
    if (!hash || hash == "#")

        return "";

    if (hash.startsWith("#"))

        hash = hash.substring(1);

    return hash;
}

function addPath (properties: Record<string, string>, path: string)
{
    if (path)

        properties.view_path = path;
}

function addHash (properties: Record<string, string>, hash: string)
{
    if (hash)

        properties.view_hash = hash;
}

function addQuery (properties: Record<string, string>, query: LocationQuery)
{
    if (query)
    {
        for (const [key, value] of Object.entries(query))
        {
            if (key && value)

                properties["view_query_" + key] = value?.toString();
        }
    }
}
