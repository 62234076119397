<template>
    <div>
        <metainfo>
            <template #title="{ content }">
                {{ content ? `${content} | Visual Design Cafe` : `Visual Design Cafe` }}
            </template>
        </metainfo>
        <PageLoadingBar />
        <router-view />
        <div
            v-if="envMode === 'staging' || envMode === 'development'"
            class="fixed bottom-0 left-0 z-50 p-1 px-2 m-1 text-xs text-white bg-black rounded bg-opacity-80"
        >
            <span class="text-gray-400">environment:</span> {{ envMode }} <span class="text-gray-400">- project:</span>
            <a
                :href="'https://console.firebase.google.com/project/'+projectId"
                class="hover:underline"
                target="_blank"
            >{{ projectId }}
            </a>
            <span class="text-gray-400">- auth:</span>
            <a
                :href="'https://console.firebase.google.com/project/'+projectId+'/authentication/users'"
                class="hover:underline"
                target="_blank"
            >{{ appAuthDomain }}
            </a>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { useMeta } from "vue-meta";
import { getApp } from "firebase/app";
import PageLoadingBar from "../components/PageLoadingBar.vue";

const env = inject("env");
const envMode = computed(() => env.mode);
const app = getApp();
const projectId = computed(() => app.options.projectId);
const appAuthDomain = computed(() => app.options.authDomain);

useMeta({
    title: "",
    htmlAttrs: { lang: "en", amp: false },
    og: {
        site_name: "Visual Design Cafe",
        locale: "en_US"
    }
});

</script>
<style scoped>
#app {
}

.code-highlight {
    @apply shadow p-0.5 rounded bg-white;
}

.code-bracket {
    @apply text-xs;
    @apply leading-3;
}

.code-class {
    @apply text-blue-700;
}

.code-string {
    @apply text-red-700;
}

.code-tag {
    @apply text-gray-400;
}

.code-comment {
    @apply text-green-700;
}
</style>
