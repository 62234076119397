import { RouteLocationNormalized } from "vue-router";
import { reactive } from "vue";
import * as MixPanel from "./mixpanel";

declare global
{
    interface Window
    {
        gtag: any;
    }
}

interface ConsentSettings
{
    performance: boolean,
    targeting: boolean,
}

export enum AuthProvider
    {
    Email = "Email",
    Google = "Google"
}

export enum GoogleAdsConversionLabel
    {
    SubscribeNatureRenderer = "PPyKCJjrg6oDEI29psAD"
}

export interface AnalyticsState
{
    experimentPath: Record<string, string>;
}

export const analyticsState = reactive({
    experimentPath: {}
} as AnalyticsState);

/**
 * Initialize analytics for the website based on the current environment.
 * @param env
 */
export function init (env: { mode: string })
{
    const productionPropertyId = "G-5XMV3Y2GST";
    const stagingPropertyId = "G-3J8L1V3LBE";
    const adsStagingId = "AW-940154509";
    const adsProductionId = "AW-940154509";
    const optimizeStagingId = "OPT-NCD5WGG";
    const optimizeProductionId = "OPT-MNQCZL8";

    window.gtag("js", new Date());
    window.gtag(
        "config",
        env.mode == "production" ? productionPropertyId : stagingPropertyId,
        {
            // Do not send initial pageview, the pageview is sent when the history is changed by the router.
            send_page_view: false
        });
    window.gtag("config", env.mode == "production" ? adsProductionId : adsStagingId);
    window.gtag("config", env.mode == "production" ? optimizeProductionId : optimizeStagingId);
    window.gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied"
    });

    MixPanel.init(env);
}

/**
 * Set the consent of the user to allow analytics and/or ads.
 * @param consent
 * @param route
 */
export function setConsent (consent: ConsentSettings)
{
    MixPanel.setOptIn(consent && consent.performance);

    window.gtag("consent", "update", {
        ad_storage: consent && consent.targeting ? "granted" : "denied",
        analytics_storage: consent && consent.performance ? "granted" : "denied"
    });
}

export function setMedium (medium: string)
{
    window.gtag("set", "campaign_medium", medium);
    MixPanel.setPropertyOnce("first_medium", medium);
    MixPanel.setProperty("last_medium", medium);
}

export function setCampaign (name: string)
{
    window.gtag("set", "campaign_name", name);
    MixPanel.setPropertyOnce("first_campaign_name", name);
    MixPanel.setProperty("last_campaign_name", name);
}

export function setSource (source: string)
{
    window.gtag("set", "campaign_source", source);
    MixPanel.setPropertyOnce("first_source", source);
    MixPanel.setProperty("last_source", source);
}

/**
 * Identify a signed-in user with their unique user ID.
 * @param userId
 */
export function setUser (userId: string)
{
    MixPanel.setUser(userId);
    window.gtag("config", "G-3J8L1V3LBE", {
        user_id: userId
    });
}

/**
 * Track a page view event.
 * @param route
 */
export function trackPageView (route: RouteLocationNormalized)
{
    MixPanel.trackPageView(route);
    // gtag tracks page views automatically, so there is no need to send them.
}

export function trackExperimentImpression (experimentId: string, variantId: string)
{
    if (experimentId && variantId)

        window.gtag("event", "experiment_impression", { experiment_id: experimentId, variant_id: variantId });
}

export function trackLogin (provider: AuthProvider)
{
    window.gtag("event", "login", { method: provider });
    MixPanel.track("Login", {
        auth_provider: provider
    });
}

export function trackSignup (provider: AuthProvider)
{
    window.gtag("event", "sign_up", { method: provider });
    MixPanel.track("Sign Up", {
        auth_provider: provider
    });
}

export function trackAdsConversion (conversionLabel: string)
{
    if (conversionLabel)
    {
        window.gtag("event", "conversion", {
            send_to: "AW-940154509/" + conversionLabel
        });
    }
}

export function trackStartTrial (price: number, itemId: string, itemName: string)
{
    window.gtag("event", "start_trial", {
        items: [
            {
                item_id: itemId,
                item_name: itemName,
                currency: "USD",
                price: price,
                quantity: 1
            }
        ]
    });
    MixPanel.track("Start Trial", {
        currency: "USD",
        quantity: 1,
        price: price,
        item_id: itemId,
        item_name: itemName
    });
}

export function trackCompleteCheckout (transactionId: string, price: number, itemId: string, itemName: string)
{
    window.gtag("event", "complete_checkout", {
        currency: "USD",
        transaction_id: transactionId,
        value: price,
        items: [
            {
                item_id: itemId,
                item_name: itemName,
                currency: "USD",
                price: price,
                quantity: 1
            }
        ]
    });
    MixPanel.track("Complete Checkout", {
        currency: "USD",
        quantity: 1,
        price: price,
        item_id: itemId,
        item_name: itemName
    });
}

export function trackBeginCheckout (price: number, itemId: string, itemName: string)
{
    window.gtag("event", "begin_checkout", {
        currency: "USD",
        value: price,
        items: [
            {
                item_id: itemId,
                item_name: itemName,
                currency: "USD",
                price: price,
                quantity: 1
            }
        ]
    });
    MixPanel.track("Begin Checkout", {
        currency: "USD",
        quantity: 1,
        price: price,
        item_id: itemId,
        item_name: itemName
    });
}
