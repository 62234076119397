import { analyticsState, trackExperimentImpression } from "../services/analytics";

export function assignVariant (experimentId: string, variant: number)
{
    localStorage["exp." + experimentId] = variant;
}

/**
 * Assign a random variant for the experiment and store it in local storage.
 */
export function assignRandomVariant (experimentId: string, variants: number): number
{
    if (!experimentId)
        return -1;

    const value: number = Math.floor(Math.random() * variants);
    assignVariant(experimentId, value);
    return value;
}

/**
 * Load the assigned variant from local storage.
 */
export function getAssignedVariant (experimentId: string)
{
    if (!experimentId)
        return -1;

    return localStorage["exp." + experimentId];
}

export function trackImpressionOncePerPage (experimentId: string, path: string, variant: string)
{
    if (analyticsState.experimentPath[experimentId] != path)
    {
        analyticsState.experimentPath[experimentId] = path;
        trackExperimentImpression(experimentId, variant);
    }
}
