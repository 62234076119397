<template>
    <div class="fixed z-50 w-full h-1">
        <div
            :class="show ? 'opacity-100 transition-none' : 'opacity-0 transition-opacity'"
            :style="{width: (progress*100)+'%'}"
            class="absolute top-0 left-0 h-full duration-500 rounded-full bg-gradient-to-r from-purple-500 to-purple-400"
        >
            <div class="absolute top-0 right-0 w-1 h-full bg-purple-100 rounded-full" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const progress = ref<number>(0.0);
const show = ref<boolean>(false);

let isDone = false;
let incTimeout: any;
let baseSpeed = 0.1;
let randomSpeed = 0.2;

router.beforeEach((to, from) =>
{
    if (to.path != from.path)

        start();
});

router.afterEach(() =>
{
    done();
});

function start ()
{
    isDone = false;
    progress.value = 0;
    baseSpeed = 0.1;
    randomSpeed = 0.2;
    show.value = true;
    clearTimeout(incTimeout);
    inc();
}

function done ()
{
    clearTimeout(incTimeout);
    if (!isDone)
    {
        isDone = true;
        setTimeout(() =>
        {
            progress.value = 1;
            setTimeout(hide, 500);
        }, 200);
    }
}

function inc ()
{
    progress.value = Number(progress.value) + baseSpeed + Math.random() * randomSpeed;
    baseSpeed = Math.max(baseSpeed * 0.8, 0.005);
    randomSpeed = Math.max(randomSpeed * 0.8, 0.005);
    if (progress.value >= 1.0)
    {
        progress.value = 1.0;
        done();
    }
    else
    {
        incTimeout = setTimeout(inc, 300 + Math.random() * 100);
    }
}

function hide ()
{
    show.value = false;
}
</script>
