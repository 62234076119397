import { VueCookieNext } from "vue-cookie-next";

export let cookieSettings;

/**
 * Loads the cookie consent settings from the cookie that is stored in the browser.
 * @returns The cookie settings
 */
export function loadCookieSettings ()
{
    if (VueCookieNext.isCookieAvailable("cookies_consent"))
    {
        const cookie = VueCookieNext.getCookie("cookies_consent");

        cookieSettings = {
            essential: true,
            performance: cookie.allowPerformance,
            functional: cookie.allowFunctional,
            targeting: cookie.allowAdvertising
        };
    }
    else
    {
        cookieSettings = undefined;
    }

    return cookieSettings;
}

/**
 * Saves the given cookie consent settings to a cookie in the browser.
 * The saved settings can be accessed by either using loadCookieSettings()
 * or by directly accessing the cookieSettings object.
 * @param {boolean} essential
 * @param {boolean} performance
 * @param {boolean} functional
 * @param {boolean} targeting
 */
export function saveCookieSettings (essential, performance, functional, targeting)
{
    cookieSettings = {
        essential: essential,
        performance: performance,
        functional: functional,
        targeting: targeting
    };

    VueCookieNext.setCookie(
        "cookies_consent",
        {
            allow: essential,
            allowPerformance: performance,
            allowFunctional: functional,
            allowAdvertising: targeting
        },
        {
            expire: "150d"
        }
    );
}

/**
 * Removes the stored cookie settings.
 */
export function removeCookieSettings ()
{
    VueCookieNext.removeCookie("cookies_consent");
    cookieSettings = undefined;
}
