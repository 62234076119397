import { createApp } from "vue";
import App from "../pages/App.vue";
import "./style.css";
import router from "./router";
import { createMetaManager } from "vue-meta";
import { VueCookieNext } from "vue-cookie-next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { loadCookieSettings } from "../../legal/services/cookieConsent.js";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { store } from "./store.js";
import * as Analytics from "./analytics";
import VueSocialSharing from "vue-social-sharing";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

async function isReachable (url: string)
{
    return new Promise((resolve) =>
    {
        fetch(url, { mode: "no-cors" })
            .then(() =>
            {
                resolve(true);
            })
            .catch(() =>
            {
                resolve(false);
            });
    });
}

async function setup ()
{
    // Env
    const env = {
        mode: import.meta.env.MODE
    };

    console.log("environment:", env.mode);

    // Load cookie consent settings
    const cookieSettings = loadCookieSettings();

    // Initialize analytics and opt-in the user if cookies are enabled.
    Analytics.init(env);
    Analytics.setConsent(cookieSettings);

    const app = createApp(App);

    // Env
    app.config.globalProperties.$env = env;
    app.provide("env", app.config.globalProperties.$env);

    // Router
    app.use(router);

    // Vue Social Sharing
    app.use(VueSocialSharing);

    // Meta tags
    app.use(createMetaManager());

    // Cookies
    app.use(VueCookieNext);
    VueCookieNext.config({ expire: "14d" });

    // Paddle
    const paddleStagingConfig = {
        vendorId: 140243
    };

    const paddleProductionConfig = {
        vendorId: 133785
    };

    app.config.globalProperties.$paddleConfig =
        (env.mode == "production") ? paddleProductionConfig : paddleStagingConfig;
    app.provide("paddleConfig", app.config.globalProperties.$paddleConfig);

    // Firebase
    const firebaseStagingConfig = {
        apiKey: "AIzaSyA32iwPUAZWOs9mdc3aYigSwq5tXDR4KIM",
        authDomain: "staging.visualdesigncafe.com",
        projectId: "vdc-web-staging",
        storageBucket: "vdc-web-staging.appspot.com",
        messagingSenderId: "1015276036705",
        appId: "1:1015276036705:web:9f01ed7fb7164f45b28020"
    };

    const firebaseProductionConfig = {
        apiKey: "AIzaSyCFszDYBeQE_5gh4yER3QnT-_VYgonAwkU",
        authDomain: "visualdesigncafe.com",
        projectId: "vdc-web-production",
        storageBucket: "vdc-web-production.appspot.com",
        messagingSenderId: "826783558219",
        appId: "1:826783558219:web:8d23096aa614a0e337fbce"
    };

    app.config.globalProperties.$firebase =
        initializeApp(env.mode == "production" ? firebaseProductionConfig : firebaseStagingConfig);
    app.config.globalProperties.$firebaseAuth = getAuth(app.config.globalProperties.$firebase);
    app.config.globalProperties.$googleAuthProvider = new GoogleAuthProvider();
    app.config.globalProperties.$firebaseFunctions = getFunctions();

    if (env.mode == "development")
    {
        const reachable = await isReachable("http://localhost:5001");
        if (reachable)
        {
            console.log("Firebase Emulator is running. Connecting Cloud Functions to emulator");
            connectFunctionsEmulator(
                app.config.globalProperties.$firebaseFunctions, "localhost", 5001);
        }
        else
        {
            console.log(
                "Firebase Emulator is not running. "
                + "Cloud Functions will connect to live staging server");
        }
    }

    app.provide("firebase", app.config.globalProperties.$firebase);
    app.provide("firebaseAuth", app.config.globalProperties.$firebaseAuth);
    app.provide("googleAuthProvider", app.config.globalProperties.$googleAuthProvider);
    app.provide("firebaseFunctions", app.config.globalProperties.$firebaseFunctions);

    // Vuex
    app.use(store);
    onAuthStateChanged(
        app.config.globalProperties.$firebaseAuth,
        (user) =>
        {
            if (user)
            {
                Analytics.setConsent({
                    targeting: true,
                    performance: true
                });
                Analytics.setUser(user.uid);
            }
            store.dispatch("fetchUser", user);
        });

    if (env.mode == "production")
    {
        Sentry.init({
            app,
            dsn: "https://7c43d94ccc6b41daacbfd4871104cdfd@o1418417.ingest.sentry.io/6761525",
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: ["localhost", "v3.visualdesigncafe.com", "visualdesigncafe.com", /^\//]
                })
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.25,
            logErrors: true,
            ignoreErrors: [
                "Unable to preload CSS for"
            ],
            environment: env.mode || "unknown"
        });
    }

    app.mount("#app");
}

/*
if (!Array.prototype.flat)
{
    Object.defineProperty(Array.prototype, "flat", {
        value: function (depth = 1)
        {
            return this.reduce(function (flat, toFlatten)
            {
                return flat.concat((Array.isArray(toFlatten) && (depth > 1)) ? toFlatten.flat(depth - 1) : toFlatten);
            }, []);
        }
    });
}
*/

setup();
