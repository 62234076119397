import { createRouter, createWebHistory, RouteLocationNormalized } from "vue-router";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { store } from "./store.js";
import * as Analytics from "./analytics";
import { assignVariant } from "./experiments";

import NatureRendererFeaturedImage from "../../docs/assets/nature-renderer/nature-renderer-2021-banner-1@2x.jpg";
import NatureRendererProfilerFeaturedImage
    from "../../docs/assets/nature-renderer/nature-renderer-2021-profiler-banner@2x.jpg";
import NatureRendererMotionVectorsFeaturedImage
    from "../../docs/assets/nature-renderer/nature-renderer-doc-motion-vectors@2x.jpg";
import NatureRendererNatureInstanceFeaturedImage
    from "../../docs/assets/nature-renderer/nature-renderer-doc-nature-instance.jpg";

const routes = [
    // The current website does not have a homepage yet.
    // The homepage is still hosted on the hold URL.
    // Redirect the user to the nature renderer page by default.
    {
        name: "Home",
        path: "/",
        component: () => import("../../products/pages/Home.vue")
    },
    {
        name: "Document",
        path: "/docs",
        component: () => import("../../products/pages/DocsHome.vue")
    },
    {
        name: "Support",
        path: "/support",
        component: () => import("../../products/pages/SupportHome.vue")
    },
    // Learn
    {
        name: "Learn",
        path: "/learn",
        redirect: "/learn/nested-prefabs",
        component: () => import("../../learn/pages/Article.vue"),
        children: [
            {
                name: "Nested Prefabs",
                path: "nested-prefabs",
                component: () => import("../../learn/pages/ArticleNestedPrefabs.vue")
            },
            {
                path: "how-to-use-lod-group-for-terrain-details",
                component: () => import("../../landing/pages/TerrainGrassLodGroup.vue")
            }
        ]
    },
    // Products
    {
        name: "ShaderX",
        path: "/shaderX",
        component: () => import("../../products/pages/ShaderX.vue")
    },
    {
        name: "nature-shaders",
        path: "/nature-shaders",
        component: () => import("../../products/pages/NatureShaders.vue"),
        children: [
            {
                name: "Nature Shaders Overview",
                path: "",
                component: () => import("../../products/pages/NatureShadersOverview.vue")
            },
            {
                name: "nature-shaders-docs",
                path: "docs/2020",
                redirect: "/nature-shaders/docs/2020/install-or-update",
                component: () => import("../../docs/pages/nature-shaders/Docs.vue"),
                children: [
                    {
                        path: "release-notes",
                        redirect: "/nature-shaders/docs/2020/1-0-X"
                    },
                    {
                        name: "nature-shaders-docs-2020-where-to-download-nature-shaders",
                        path: "where-to-download-nature-shaders",
                        component: () => import("../../docs/pages/nature-shaders/2020/HowToDownload.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-install-or-update",
                        path: "install-or-update",
                        component: () => import("../../docs/pages/nature-shaders/2020/InstallOrUpdate.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-shaders/2020/Quickstart.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-nature-assets",
                        path: "nature-assets",
                        component: () => import("../../docs/pages/nature-shaders/2020/NatureAssets.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-material-setup",
                        path: "material-setup",
                        component: () => import("../../docs/pages/nature-shaders/2020/MaterialSetup.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-material-editor",
                        path: "material-editor",
                        component: () => import("../../docs/pages/nature-shaders/2020/MaterialEditor.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/nature-shaders/2020/Wind.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-interaction",
                        path: "interaction",
                        component: () => import("../../docs/pages/nature-shaders/2020/Interaction.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-overlay",
                        path: "overlay",
                        component: () => import("../../docs/pages/nature-shaders/2020/Overlay.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-quality-settings",
                        path: "quality-settings",
                        component: () => import("../../docs/pages/nature-shaders/2020/QualitySettings.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-performance",
                        path: "performance",
                        component: () => import("../../docs/pages/nature-shaders/2020/Performance.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-stylized-shading",
                        path: "stylized-shading",
                        component: () => import("../../docs/pages/nature-shaders/2020/StylizedShading.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-nature-renderer",
                        path: "nature-renderer",
                        component: () => import("../../docs/pages/nature-shaders/2020/NatureRenderer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-vegetation-studio",
                        path: "vegetation-studio",
                        component: () => import("../../docs/pages/nature-shaders/2020/VegetationStudio.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-gpu-instancer",
                        path: "gpu-instancer",
                        component: () => import("../../docs/pages/nature-shaders/2020/GpuInstancer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-nature-asset-api",
                        path: "nature-asset-api",
                        component: () => import("../../docs/pages/nature-shaders/2020/NatureAssetApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-material-api",
                        path: "material-api",
                        component: () => import("../../docs/pages/nature-shaders/2020/MaterialApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-interaction-api",
                        path: "interaction-api",
                        component: () => import("../../docs/pages/nature-shaders/2020/InteractionApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-overlay-api",
                        path: "overlay-api",
                        component: () => import("../../docs/pages/nature-shaders/2020/OverlayApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-common-problems",
                        path: "common-problems",
                        component: () => import("../../docs/pages/nature-shaders/2020/CommonProblems.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-shaders/2020/KnownIssues.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-1.0.X",
                        path: "1-0-X",
                        component: () => import("../../docs/pages/nature-shaders/2020/Version-1-0-X.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-0.10.X",
                        path: "0-10-X",
                        component: () => import("../../docs/pages/nature-shaders/2020/Version-0-10-X.vue")
                    },
                    {
                        name: "nature-shaders-docs-2020-0.9.X",
                        path: "0-9-X",
                        component: () => import("../../docs/pages/nature-shaders/2020/Version-0-9-X.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Shaders"
                        }
                    }
                ]
            },
            {
                name: "nature-shaders-docs-2021",
                path: "docs/2021",
                redirect: "/nature-shaders/docs/2021/install-or-update",
                component: () => import("../../docs/pages/nature-shaders/Docs.vue"),
                children: [
                    {
                        path: "1-0-X",
                        redirect: "/nature-shaders/docs/2021/release-notes"
                    },
                    {
                        path: "0-10-X",
                        redirect: "/nature-shaders/docs/2021/release-notes"
                    },
                    {
                        path: "0-9-X",
                        redirect: "/nature-shaders/docs/2021/release-notes"
                    },

                    {
                        name: "nature-shaders-docs-2021-where-to-download-nature-shaders",
                        path: "where-to-download-nature-shaders",
                        component: () => import("../../docs/pages/nature-shaders/2021/HowToDownload.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-install-or-update",
                        path: "install-or-update",
                        component: () => import("../../docs/pages/nature-shaders/2021/InstallOrUpdate.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-shaders/2021/Quickstart.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-nature-assets",
                        path: "nature-assets",
                        component: () => import("../../docs/pages/nature-shaders/2021/NatureAssets.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-material-setup",
                        path: "material-setup",
                        component: () => import("../../docs/pages/nature-shaders/2021/MaterialSetup.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-material-editor",
                        path: "material-editor",
                        component: () => import("../../docs/pages/nature-shaders/2021/MaterialEditor.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/nature-shaders/2021/Wind.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-custom-mesh-setup",
                        path: "custom-mesh-setup",
                        component: () => import("../../docs/pages/nature-shaders/2021/CustomMeshSetup.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-interaction",
                        path: "interaction",
                        component: () => import("../../docs/pages/nature-shaders/2021/Interaction.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-overlay",
                        path: "overlay",
                        component: () => import("../../docs/pages/nature-shaders/2021/Overlay.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-quality-settings",
                        path: "quality-settings",
                        component: () => import("../../docs/pages/nature-shaders/2021/QualitySettings.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-performance",
                        path: "performance",
                        component: () => import("../../docs/pages/nature-shaders/2021/Performance.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-stylized-shading",
                        path: "stylized-shading",
                        component: () => import("../../docs/pages/nature-shaders/2021/StylizedShading.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-nature-renderer",
                        path: "nature-renderer",
                        component: () => import("../../docs/pages/nature-shaders/2021/NatureRenderer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-vegetation-studio",
                        path: "vegetation-studio",
                        component: () => import("../../docs/pages/nature-shaders/2021/VegetationStudio.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-gpu-instancer",
                        path: "gpu-instancer",
                        component: () => import("../../docs/pages/nature-shaders/2021/GpuInstancer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-nature-asset-api",
                        path: "nature-asset-api",
                        component: () => import("../../docs/pages/nature-shaders/2021/NatureAssetApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-material-api",
                        path: "material-api",
                        component: () => import("../../docs/pages/nature-shaders/2021/MaterialApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-interaction-api",
                        path: "interaction-api",
                        component: () => import("../../docs/pages/nature-shaders/2021/InteractionApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-overlay-api",
                        path: "overlay-api",
                        component: () => import("../../docs/pages/nature-shaders/2021/OverlayApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-common-problems",
                        path: "common-problems",
                        component: () => import("../../docs/pages/nature-shaders/2021/CommonProblems.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-shaders/2021/KnownIssues.vue")
                    },
                    {
                        name: "nature-shaders-docs-2021-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-shaders/2021/ReleaseNotes.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Shaders"
                        }
                    }
                ]
            },
            {
                name: "nature-shaders-docs-2022",
                path: "docs/2022",
                redirect: "/nature-shaders/docs/2022/install-or-update",
                component: () => import("../../docs/pages/nature-shaders/Docs.vue"),
                children: [
                    {
                        name: "nature-shaders-docs-2022-where-to-download-nature-shaders",
                        path: "where-to-download-nature-shaders",
                        redirect: "/nature-shaders/docs/2022/installation"
                    },
                    {
                        name: "nature-shaders-docs-2022-install-or-update",
                        path: "install-or-update",
                        redirect: "/nature-shaders/docs/2022/installation"
                    },
                    {
                        name: "nature-shaders-docs-2022-installation",
                        path: "installation",
                        component: () => import("../../docs/pages/nature-shaders/2022/Installation.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-shaders/2022/Quickstart.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-nature-assets",
                        path: "nature-assets",
                        component: () => import("../../docs/pages/nature-shaders/2022/NatureAssets.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-material-setup",
                        path: "material-setup",
                        component: () => import("../../docs/pages/nature-shaders/2022/MaterialSetup.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-material-editor",
                        path: "material-editor",
                        component: () => import("../../docs/pages/nature-shaders/2022/MaterialEditor.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/nature-shaders/2022/Wind.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-custom-mesh-setup",
                        path: "custom-mesh-setup",
                        component: () => import("../../docs/pages/nature-shaders/2022/CustomMeshSetup.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-interaction",
                        path: "interaction",
                        component: () => import("../../docs/pages/nature-shaders/2022/Interaction.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-overlay",
                        path: "overlay",
                        component: () => import("../../docs/pages/nature-shaders/2022/Overlay.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-quality-settings",
                        path: "quality-settings",
                        component: () => import("../../docs/pages/nature-shaders/2022/QualitySettings.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-performance",
                        path: "performance",
                        component: () => import("../../docs/pages/nature-shaders/2022/Performance.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-stylized-shading",
                        path: "stylized-shading",
                        component: () => import("../../docs/pages/nature-shaders/2022/StylizedShading.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-nature-renderer",
                        path: "nature-renderer",
                        component: () => import("../../docs/pages/nature-shaders/2022/NatureRenderer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-vegetation-studio",
                        path: "vegetation-studio",
                        component: () => import("../../docs/pages/nature-shaders/2022/VegetationStudio.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-gpu-instancer",
                        path: "gpu-instancer",
                        component: () => import("../../docs/pages/nature-shaders/2022/GpuInstancer.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-nature-asset-api",
                        path: "nature-asset-api",
                        component: () => import("../../docs/pages/nature-shaders/2022/NatureAssetApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-material-api",
                        path: "material-api",
                        component: () => import("../../docs/pages/nature-shaders/2022/MaterialApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-interaction-api",
                        path: "interaction-api",
                        component: () => import("../../docs/pages/nature-shaders/2022/InteractionApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-overlay-api",
                        path: "overlay-api",
                        component: () => import("../../docs/pages/nature-shaders/2022/OverlayApi.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-common-problems",
                        path: "common-problems",
                        component: () => import("../../docs/pages/nature-shaders/2022/CommonProblems.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-shaders/2022/KnownIssues.vue")
                    },
                    {
                        name: "nature-shaders-docs-2022-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-shaders/2022/ReleaseNotes.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Shaders"
                        }
                    }
                ]
            },
            // Fallback, redirects invalid versions to the latest version.
            {
                path: "docs/:version/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/nature-shaders/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths without a version to the latest version.
            {
                path: "docs/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/nature-shaders/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths to the docs root to the latest version.
            {
                path: "docs",
                redirect: "/nature-shaders/docs/2021"
            }
        ]
    },
    {
        name: "Comparison",
        path: "/nature-shaders/comparison",
        component: () => import("../../products/pages/NatureShadersComparison.vue")
    },
    {
        name: "Realistic Nature",
        path: "/realistic-nature",
        component: () => import("../../products/pages/RealisticNature.vue"),
        children: [
            {
                name: "Realistic Nature Overview",
                path: "",
                component: () => import("../../products/pages/RealisticNatureOverview.vue")
            },
            {
                name: "realistic-nature-docs-2021",
                path: "docs/2021",
                redirect: "/realistic-nature/docs/2021/install-or-update",
                component: () => import("../../docs/pages/realistic-nature/Docs.vue"),
                children: [
                    {
                        name: "realistic-nature-docs-2021-installation",
                        path: "installation",
                        redirect: "/realistic-nature/docs/2021/install-or-update"
                    },
                    {
                        name: "realistic-nature-docs-2021-install-or-update",
                        path: "install-or-update",
                        component: () => import("../../docs/pages/realistic-nature/2021/InstallOrUpdate.vue")
                    },
                    {
                        name: "realistic-nature-docs-2021-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/realistic-nature/2021/Quickstart.vue")
                    },
                    {
                        name: "realistic-nature-docs-2021-shaders",
                        path: "shaders",
                        component: () => import("../../docs/pages/realistic-nature/2021/Shaders.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Realistic Nature"
                        }
                    }
                ]
            },
            {
                name: "realistic-nature-docs-2022",
                path: "docs/2022",
                redirect: "/realistic-nature/docs/2022/install-or-update",
                component: () => import("../../docs/pages/realistic-nature/Docs.vue"),
                children: [
                    {
                        name: "realistic-nature-docs-2022-install-or-update",
                        path: "install-or-update",
                        redirect: "/realistic-nature/docs/2022/installation"
                    },
                    {
                        name: "realistic-nature-docs-2022-installation",
                        path: "installation",
                        component: () => import("../../docs/pages/realistic-nature/2022/InstallOrUpdate.vue")
                    },
                    {
                        name: "realistic-nature-docs-2022-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/realistic-nature/2022/Quickstart.vue")
                    },
                    {
                        name: "realistic-nature-docs-2022-shaders",
                        path: "shaders",
                        component: () => import("../../docs/pages/realistic-nature/2022/Shaders.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Realistic Nature"
                        }
                    }
                ]
            },
            {
                name: "realistic-nature-docs",
                path: "docs/2020",
                redirect: "/realistic-nature/docs/2020/install-or-update",
                component: () => import("../../docs/pages/realistic-nature/Docs.vue"),
                children: [
                    {
                        name: "realistic-nature-docs-2020-install-or-update",
                        path: "install-or-update",
                        component: () => import("../../docs/pages/realistic-nature/2020/InstallOrUpdate.vue")
                    },
                    {
                        name: "realistic-nature-docs-2020-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/realistic-nature/2020/Quickstart.vue")
                    },
                    {
                        name: "realistic-nature-docs-2020-shaders",
                        path: "shaders",
                        component: () => import("../../docs/pages/realistic-nature/2020/Shaders.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Realistic Nature"
                        }
                    }
                ]
            },
            // Fallback, redirects invalid versions to the latest version.
            {
                path: "docs/:version/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/realistic-nature/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths without a version to the latest version.
            {
                path: "docs/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/realistic-nature/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths to the docs root to the latest version.
            {
                path: "docs",
                redirect: "/realistic-nature/docs/2021"
            }
        ]
    },
    {
        name: "Stylized Nature",
        path: "/stylized-nature",
        component: () => import("../../products/pages/StylizedNature.vue"),
        children: [
            {
                name: "Stylized Nature Overview",
                path: "",
                component: () => import("../../products/pages/StylizedNatureOverview.vue")
            },
            {
                name: "stylized-nature-docs-2021",
                path: "docs/2021",
                redirect: "/stylized-nature/docs/2021/package-contents",
                component: () => import("../../docs/pages/stylized-nature/Docs.vue"),
                children: [
                    {
                        name: "stylized-nature-docs-2021-package-contents",
                        path: "package-contents",
                        component: () => import("../../docs/pages/stylized-nature/2021/PackageContents.vue")
                    },
                    {
                        name: "stylized-nature-docs-2021-color-space",
                        path: "color-space",
                        component: () => import("../../docs/pages/stylized-nature/2021/ColorSpace.vue")
                    },
                    {
                        name: "stylized-nature-docs-2021-post-processing",
                        path: "post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2021/PostProcessing.vue")
                    },
                    {
                        name: "stylized-nature-docs-2021-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/stylized-nature/2021/Wind.vue")
                    },
                    {
                        name: "stylized-nature-docs-2021-importing-unity-post-processing-stack",
                        path: "importing-unity-post-processing-stack",
                        component: () => import("../../docs/pages/stylized-nature/2021/ImportingUnity.vue")
                    },
                    {
                        name: "stylized-nature-docs-2021-setting-up-post-processing",
                        path: "setting-up-post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2021/SettingUp.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Stylized Nature"
                        }
                    }
                ]
            },
            {
                name: "stylized-nature-docs-2022",
                path: "docs/2022",
                redirect: "/stylized-nature/docs/2022/package-contents",
                component: () => import("../../docs/pages/stylized-nature/Docs.vue"),
                children: [
                    {
                        name: "stylized-nature-docs-2022-package-contents",
                        path: "package-contents",
                        component: () => import("../../docs/pages/stylized-nature/2022/PackageContents.vue")
                    },
                    {
                        name: "stylized-nature-docs-2022-color-space",
                        path: "color-space",
                        component: () => import("../../docs/pages/stylized-nature/2022/ColorSpace.vue")
                    },
                    {
                        name: "stylized-nature-docs-2022-post-processing",
                        path: "post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2022/PostProcessing.vue")
                    },
                    {
                        name: "stylized-nature-docs-2022-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/stylized-nature/2022/Wind.vue")
                    },
                    {
                        name: "stylized-nature-docs-2022-importing-unity-post-processing-stack",
                        path: "importing-unity-post-processing-stack",
                        component: () => import("../../docs/pages/stylized-nature/2022/ImportingUnity.vue")
                    },
                    {
                        name: "stylized-nature-docs-2022-setting-up-post-processing",
                        path: "setting-up-post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2022/SettingUp.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Stylized Nature"
                        }
                    }
                ]
            },
            {
                name: "stylized-nature-docs-2020",
                path: "docs/2020",
                redirect: "/stylized-nature/docs/2020/package-contents",
                component: () => import("../../docs/pages/stylized-nature/Docs.vue"),
                children: [
                    {
                        name: "stylized-nature-docs-2020-package-contents",
                        path: "package-contents",
                        component: () => import("../../docs/pages/stylized-nature/2020/PackageContents.vue")
                    },
                    {
                        name: "stylized-nature-docs-2020-color-space",
                        path: "color-space",
                        component: () => import("../../docs/pages/stylized-nature/2020/ColorSpace.vue")
                    },
                    {
                        name: "stylized-nature-docs-2020-post-processing",
                        path: "post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2020/PostProcessing.vue")
                    },
                    {
                        name: "stylized-nature-docs-2020-wind",
                        path: "wind",
                        component: () => import("../../docs/pages/stylized-nature/2020/Wind.vue")
                    },
                    {
                        name: "stylized-nature-docs-2020-importing-unity-post-processing-stack",
                        path: "importing-unity-post-processing-stack",
                        component: () => import("../../docs/pages/stylized-nature/2020/ImportingUnity.vue")
                    },
                    {
                        name: "stylized-nature-docs-2020-setting-up-post-processing",
                        path: "setting-up-post-processing",
                        component: () => import("../../docs/pages/stylized-nature/2020/SettingUp.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Stylized Nature"
                        }
                    }
                ]
            },
            // Fallback, redirects invalid versions to the latest version.
            {
                path: "docs/:version/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/stylized-nature/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths without a version to the latest version.
            {
                path: "docs/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/stylized-nature/docs/2021/" + to.params.article;
                }
            },
            // Fallback, redirects paths to the docs root to the latest version.
            {
                path: "docs",
                redirect: "/stylized-nature/docs/2021"
            }
        ]
    },
    {
        name: "Nature Renderer",
        path: "/nature-renderer",
        component: () => import("../../products/pages/NatureRenderer.vue"),
        children: [
            {
                name: "Nature Renderer Overview",
                path: "",
                component: () => import("../../products/pages/NatureRendererOverview.vue")
            },
            {
                name: "Pricing",
                path: "pricing",
                component: () => import("../../products/pages/NatureRendererPricing.vue")
            },
            {
                name: "nature-renderer-docs-6",
                path: "docs/6",
                redirect: "/nature-renderer/docs/6/installation",
                component: () => import("../../docs/pages/nature-renderer/Docs.vue"),
                children: [
                    {
                        name: "nature-renderer-docs-6-install-or-update",
                        path: "install-or-update",
                        redirect: "/nature-renderer/docs/6/installation"
                    },
                    {
                        name: "nature-renderer-docs-6-installation",
                        path: "installation",
                        component: () => import("../../docs/pages/nature-renderer/6/Installation.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-6-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-renderer/6/Quickstart.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-6-source-code",
                        path: "source-code",
                        component: () => import("../../docs/pages/nature-renderer/6/SourceCode.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-render-pipeline",
                        path: "render-pipeline",
                        component: () => import("../../docs/pages/nature-renderer/6/RenderPipeline.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-terrain-vegetation",
                        path: "terrain-vegetation",
                        component: () => import("../../docs/pages/nature-renderer/6/TerrainVegetation.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-asset-setup",
                        path: "asset-setup",
                        component: () => import("../../docs/pages/nature-renderer/6/AssetSetup.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-shader-graph",
                        path: "shader-graph",
                        component: () => import("../../docs/pages/nature-renderer/6/ShaderGraph.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-custom-shaders",
                        path: "custom-shaders",
                        component: () => import("../../docs/pages/nature-renderer/6/CustomShaders.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-render-settings",
                        path: "render-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/RenderSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-profiling",
                        path: "profiling",
                        component: () => import("../../docs/pages/nature-renderer/6/Profiling.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererProfilerFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-6-culling",
                        path: "culling",
                        component: () => import("../../docs/pages/nature-renderer/6/Culling.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-streaming",
                        path: "streaming",
                        component: () => import("../../docs/pages/nature-renderer/6/Streaming.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-level-of-detail",
                        path: "level-of-detail",
                        component: () => import("../../docs/pages/nature-renderer/6/LevelOfDetail.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-motion-vectors",
                        path: "motion-vectors",
                        component: () => import("../../docs/pages/nature-renderer/6/MotionVectors.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererMotionVectorsFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-6-lighting",
                        path: "lighting",
                        component: () => import("../../docs/pages/nature-renderer/6/Lighting.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-shadows",
                        path: "shadows",
                        component: () => import("../../docs/pages/nature-renderer/6/Shadows.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-floating-origin",
                        path: "floating-origin",
                        component: () => import("../../docs/pages/nature-renderer/6/FloatingOrigin.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-collision",
                        path: "collision",
                        component: () => import("../../docs/pages/nature-renderer/6/Collision.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-density",
                        path: "density",
                        component: () => import("../../docs/pages/nature-renderer/6/Density.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-global-settings",
                        path: "global-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/GlobalSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-camera-settings",
                        path: "camera-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/CameraSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-object-settings",
                        path: "object-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/ObjectSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-default-settings",
                        path: "default-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/DefaultSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-terrain-settings",
                        path: "terrain-settings",
                        component: () => import("../../docs/pages/nature-renderer/6/TerrainSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-errors-and-warnings",
                        path: "errors-and-warnings",
                        component: () => import("../../docs/pages/nature-renderer/6/ErrorsAndWarnings.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-rendering-issues",
                        path: "rendering-issues",
                        component: () => import("../../docs/pages/nature-renderer/6/RenderingIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-performance-issues",
                        path: "performance-issues",
                        component: () => import("../../docs/pages/nature-renderer/6/PerformanceIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-compatibility",
                        path: "compatibility",
                        component: () => import("../../docs/pages/nature-renderer/6/Compatibility.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-updating",
                        path: "updating",
                        component: () => import("../../docs/pages/nature-renderer/6/Updating.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-upgrade-guide",
                        path: "upgrade-guide",
                        component: () => import("../../docs/pages/nature-renderer/6/UpgradeGuide.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-renderer/6/ReleaseNotes.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-renderer/6/KnownIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-grass-textures",
                        path: "grass-textures",
                        component: () => import("../../docs/pages/nature-renderer/6/GrassTextures.vue")
                    },
                    {
                        name: "nature-renderer-docs-6-nature-instances",
                        path: "nature-instances",
                        component: () => import("../../docs/pages/nature-renderer/6/NatureInstance.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererNatureInstanceFeaturedImage
                            }
                        }
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Renderer"
                        }
                    }
                ]
            },
            {
                name: "nature-renderer-docs-2022",
                path: "docs/2022",
                redirect: "/nature-renderer/docs/2022/installation",
                component: () => import("../../docs/pages/nature-renderer/Docs.vue"),
                children: [
                    {
                        name: "nature-renderer-docs-2022-install-or-update",
                        path: "install-or-update",
                        redirect: "/nature-renderer/docs/2022/installation"
                    },
                    {
                        name: "nature-renderer-docs-2022-installation",
                        path: "installation",
                        component: () => import("../../docs/pages/nature-renderer/2022/Installation.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2022-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-renderer/2022/Quickstart.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2022-render-pipeline",
                        path: "render-pipeline",
                        component: () => import("../../docs/pages/nature-renderer/2022/RenderPipeline.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-terrain-vegetation",
                        path: "terrain-vegetation",
                        component: () => import("../../docs/pages/nature-renderer/2022/TerrainVegetation.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-asset-setup",
                        path: "asset-setup",
                        component: () => import("../../docs/pages/nature-renderer/2022/AssetSetup.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-shader-graph",
                        path: "shader-graph",
                        component: () => import("../../docs/pages/nature-renderer/2022/ShaderGraph.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-custom-shaders",
                        path: "custom-shaders",
                        component: () => import("../../docs/pages/nature-renderer/2022/CustomShaders.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-render-settings",
                        path: "render-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/RenderSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-profiling",
                        path: "profiling",
                        component: () => import("../../docs/pages/nature-renderer/2022/Profiling.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererProfilerFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2022-culling",
                        path: "culling",
                        component: () => import("../../docs/pages/nature-renderer/2022/Culling.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-streaming",
                        path: "streaming",
                        component: () => import("../../docs/pages/nature-renderer/2022/Streaming.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-level-of-detail",
                        path: "level-of-detail",
                        component: () => import("../../docs/pages/nature-renderer/2022/LevelOfDetail.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-motion-vectors",
                        path: "motion-vectors",
                        component: () => import("../../docs/pages/nature-renderer/2022/MotionVectors.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererMotionVectorsFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2022-lighting",
                        path: "lighting",
                        component: () => import("../../docs/pages/nature-renderer/2022/Lighting.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-shadows",
                        path: "shadows",
                        component: () => import("../../docs/pages/nature-renderer/2022/Shadows.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-floating-origin",
                        path: "floating-origin",
                        component: () => import("../../docs/pages/nature-renderer/2022/FloatingOrigin.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-collision",
                        path: "collision",
                        component: () => import("../../docs/pages/nature-renderer/2022/Collision.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-density",
                        path: "density",
                        component: () => import("../../docs/pages/nature-renderer/2022/Density.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-global-settings",
                        path: "global-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/GlobalSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-camera-settings",
                        path: "camera-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/CameraSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-object-settings",
                        path: "object-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/ObjectSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-default-settings",
                        path: "default-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/DefaultSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-terrain-settings",
                        path: "terrain-settings",
                        component: () => import("../../docs/pages/nature-renderer/2022/TerrainSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-errors-and-warnings",
                        path: "errors-and-warnings",
                        component: () => import("../../docs/pages/nature-renderer/2022/ErrorsAndWarnings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-rendering-issues",
                        path: "rendering-issues",
                        component: () => import("../../docs/pages/nature-renderer/2022/RenderingIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-performance-issues",
                        path: "performance-issues",
                        component: () => import("../../docs/pages/nature-renderer/2022/PerformanceIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-compatibility",
                        path: "compatibility",
                        component: () => import("../../docs/pages/nature-renderer/2022/Compatibility.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-updating",
                        path: "updating",
                        component: () => import("../../docs/pages/nature-renderer/2022/Updating.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-upgrade-guide",
                        path: "upgrade-guide",
                        component: () => import("../../docs/pages/nature-renderer/2022/UpgradeGuide.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-renderer/2022/ReleaseNotes.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-renderer/2022/KnownIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-grass-textures",
                        path: "grass-textures",
                        component: () => import("../../docs/pages/nature-renderer/2022/GrassTextures.vue")
                    },
                    {
                        name: "nature-renderer-docs-2022-nature-instances",
                        path: "nature-instances",
                        component: () => import("../../docs/pages/nature-renderer/2022/NatureInstance.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererNatureInstanceFeaturedImage
                            }
                        }
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Renderer"
                        }
                    }
                ]
            },
            {
                name: "nature-renderer-docs-2021",
                path: "docs/2021",
                redirect: "/nature-renderer/docs/2021/installation",
                component: () => import("../../docs/pages/nature-renderer/Docs.vue"),
                children: [
                    {
                        name: "nature-renderer-docs-2021-install-or-update",
                        path: "install-or-update",
                        redirect: "/nature-renderer/docs/2021/installation"
                    },
                    {
                        name: "nature-renderer-docs-2021-installation",
                        path: "installation",
                        component: () => import("../../docs/pages/nature-renderer/2021/Installation.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2021-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-renderer/2021/Quickstart.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2021-render-pipeline",
                        path: "render-pipeline",
                        component: () => import("../../docs/pages/nature-renderer/2021/RenderPipeline.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-terrain-vegetation",
                        path: "terrain-vegetation",
                        component: () => import("../../docs/pages/nature-renderer/2021/TerrainVegetation.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-asset-setup",
                        path: "asset-setup",
                        component: () => import("../../docs/pages/nature-renderer/2021/AssetSetup.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-shader-graph",
                        path: "shader-graph",
                        component: () => import("../../docs/pages/nature-renderer/2021/ShaderGraph.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-custom-shaders",
                        path: "custom-shaders",
                        component: () => import("../../docs/pages/nature-renderer/2021/CustomShaders.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-render-settings",
                        path: "render-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/RenderSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-profiling",
                        path: "profiling",
                        component: () => import("../../docs/pages/nature-renderer/2021/Profiling.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererProfilerFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2021-culling",
                        path: "culling",
                        component: () => import("../../docs/pages/nature-renderer/2021/Culling.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-streaming",
                        path: "streaming",
                        component: () => import("../../docs/pages/nature-renderer/2021/Streaming.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-level-of-detail",
                        path: "level-of-detail",
                        component: () => import("../../docs/pages/nature-renderer/2021/LevelOfDetail.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-motion-vectors",
                        path: "motion-vectors",
                        component: () => import("../../docs/pages/nature-renderer/2021/MotionVectors.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererMotionVectorsFeaturedImage
                            }
                        }
                    },
                    {
                        name: "nature-renderer-docs-2021-lighting",
                        path: "lighting",
                        component: () => import("../../docs/pages/nature-renderer/2021/Lighting.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-shadows",
                        path: "shadows",
                        component: () => import("../../docs/pages/nature-renderer/2021/Shadows.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-floating-origin",
                        path: "floating-origin",
                        component: () => import("../../docs/pages/nature-renderer/2021/FloatingOrigin.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-collision",
                        path: "collision",
                        component: () => import("../../docs/pages/nature-renderer/2021/Collision.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-density",
                        path: "density",
                        component: () => import("../../docs/pages/nature-renderer/2021/Density.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-global-settings",
                        path: "global-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/GlobalSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-camera-settings",
                        path: "camera-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/CameraSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-object-settings",
                        path: "object-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/ObjectSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-default-settings",
                        path: "default-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/DefaultSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-terrain-settings",
                        path: "terrain-settings",
                        component: () => import("../../docs/pages/nature-renderer/2021/TerrainSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-errors-and-warnings",
                        path: "errors-and-warnings",
                        component: () => import("../../docs/pages/nature-renderer/2021/ErrorsAndWarnings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-rendering-issues",
                        path: "rendering-issues",
                        component: () => import("../../docs/pages/nature-renderer/2021/RenderingIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-performance-issues",
                        path: "performance-issues",
                        component: () => import("../../docs/pages/nature-renderer/2021/PerformanceIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-compatibility",
                        path: "compatibility",
                        component: () => import("../../docs/pages/nature-renderer/2021/Compatibility.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-updating",
                        path: "updating",
                        component: () => import("../../docs/pages/nature-renderer/2021/Updating.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-upgrade-guide",
                        path: "upgrade-guide",
                        component: () => import("../../docs/pages/nature-renderer/2021/UpgradeGuide.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-renderer/2021/ReleaseNotes.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-renderer/2021/KnownIssues.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-grass-textures",
                        path: "grass-textures",
                        component: () => import("../../docs/pages/nature-renderer/2021/GrassTextures.vue")
                    },
                    {
                        name: "nature-renderer-docs-2021-nature-instances",
                        path: "nature-instances",
                        component: () => import("../../docs/pages/nature-renderer/2021/NatureInstance.vue"),
                        meta: {
                            article: {
                                featuredImage: NatureRendererNatureInstanceFeaturedImage
                            }
                        }
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Renderer"
                        }
                    }
                ]
            },
            {
                name: "Nature Renderer Docs",
                path: "docs/2020",
                redirect: "/nature-renderer/docs/2020/install-or-update",
                component: () => import("../../docs/pages/nature-renderer/Docs.vue"),
                children: [
                    {
                        name: "nature-renderer-docs-2020-install-or-update",
                        path: "install-or-update",
                        component: () => import("../../docs/pages/nature-renderer/2020/InstallOrUpdate.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-updating",
                        path: "updating",
                        redirect: "/nature-renderer/docs/2020/install-or-update"
                    },
                    {
                        name: "nature-renderer-docs-2020-installation",
                        path: "installation",
                        redirect: "/nature-renderer/docs/2020/install-or-update"
                    },
                    {
                        name: "nature-renderer-docs-2020-quickstart",
                        path: "quickstart",
                        component: () => import("../../docs/pages/nature-renderer/2020/Quickstart.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-enable-nature-renderer-for-your-terrain",
                        path: "enable-nature-renderer-for-your-terrain",
                        component: () => import("../../docs/pages/nature-renderer/2020/EnableForYourTerrain.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-add-and-place-objects",
                        path: "add-and-place-objects",
                        component: () => import("../../docs/pages/nature-renderer/2020/AddAndPlaceObjects.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-objects-settings",
                        path: "objects-settings",
                        component: () => import("../../docs/pages/nature-renderer/2020/ObjectsSettings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-rendering",
                        path: "rendering",
                        component: () => import("../../docs/pages/nature-renderer/2020/Rendering.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-custom-shaders",
                        path: "custom-shaders",
                        component: () => import("../../docs/pages/nature-renderer/2020/CustomShaders.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-streaming",
                        path: "streaming",
                        component: () => import("../../docs/pages/nature-renderer/2020/Streaming.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-render-distance-limit",
                        path: "render-distance-limit",
                        component: () => import("../../docs/pages/nature-renderer/2020/RenderDistanceLimit.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-edit-materials",
                        path: "edit-materials",
                        component: () => import("../../docs/pages/nature-renderer/2020/EditMaterials.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-place-objects",
                        path: "place-objects",
                        component: () => import("../../docs/pages/nature-renderer/2020/PlaceObjects.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-the-vegetation-engine",
                        path: "the-vegetation-engine",
                        component: () => import("../../docs/pages/nature-renderer/2020/TheVegetationEngine.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-map-magic",
                        path: "map-magic",
                        component: () => import("../../docs/pages/nature-renderer/2020/MapMagic.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-overdraw",
                        path: "overdraw",
                        component: () => import("../../docs/pages/nature-renderer/2020/Overdraw.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-errors-and-warnings",
                        path: "errors-and-warnings",
                        component: () => import("../../docs/pages/nature-renderer/2020/ErrorsAndWarnings.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-where-to-download",
                        path: "where-to-download-nature-renderer",
                        component: () => import("../../docs/pages/nature-renderer/2020/HowToDownload.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-release-notes",
                        path: "release-notes",
                        component: () => import("../../docs/pages/nature-renderer/2020/ReleaseNotes.vue")
                    },
                    {
                        name: "nature-renderer-docs-2020-known-issues",
                        path: "known-issues",
                        component: () => import("../../docs/pages/nature-renderer/2020/KnownIssues.vue")
                    },
                    {
                        path: ":article",
                        component: () => import("../../docs/pages/ErrorPage.vue"),
                        props: {
                            productName: "Nature Renderer"
                        }
                    }
                ]
            },
            // Fallback, redirects invalid versions to the latest version.
            {
                path: "docs/:version/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/nature-renderer/docs/2022/" + to.params.article;
                }
            },
            // Fallback, redirects paths without a version to the latest version.
            {
                path: "docs/:article",
                redirect: (to: RouteLocationNormalized) =>
                {
                    return "/nature-renderer/docs/2022/" + to.params.article;
                }
            },
            // Fallback, redirects paths to the docs root to the latest version.
            {
                path: "docs",
                redirect: "/nature-renderer/docs/2022"
            }
        ]
    },
    {
        path: "/nature-renderer/pricing/change/:id",
        component: () => import("../../products/pages/NatureRendererPricingChange.vue")
    },
    {
        path: "/nature-renderer/upgrade",
        redirect: "/nature-renderer/upgrade/2022"
    },
    {
        path: "/nature-renderer/upgrade/2021",
        component: () => import("../../products/pages/NatureRendererUpgrade.vue"),
        props: { version: 2021 }
    },
    {
        path: "/nature-renderer/upgrade/2022",
        component: () => import("../../products/pages/NatureRendererUpgrade.vue"),
        props: { version: 2022 }
    },
    {
        path: "/nature-renderer/upgrade/2023",
        component: () => import("../../products/pages/NatureRendererUpgrade.vue"),
        props: { version: 2023 }
    },
    {
        path: "/nature-renderer/upgrade/6",
        component: () => import("../../products/pages/NatureRendererUpgrade.vue"),
        props: { version: 6 }
    },
    {
        path: "/nature-renderer/upgrade/:version(\\d+)+",
        component: () => import("../../products/pages/NatureRendererUpgradeNext.vue"),
        props: (route: RouteLocationNormalized) => ({
            version: Array.isArray(route.params.version)
                ? parseInt(route.params.version[0])
                : parseInt(route.params.version)
        })
    },
    {
        path: "/nature-renderer/upgrade/pricing",
        component: () => import("../../products/pages/NatureRendererUpgradePricing.vue")
    },
    {
        path: "/nature-renderer/start",
        component: () => import("../../products/pages/NatureRendererStartPricing.vue")
    },
    {
        path: "/nature-renderer/start/pricing",
        component: () => import("../../products/pages/NatureRendererStartPricing.vue")
    },

    // Legal
    {
        path: "/privacy-policy",
        redirect: "/legal/privacy-policy"
    },
    {
        name: "privacy-policy",
        path: "/legal/privacy-policy",
        component: () => import("../../legal/pages/PrivacyPolicy.vue")
    },
    {
        name: "terms-of-service",
        path: "/legal/terms-of-service",
        component: () => import("../../legal/pages/TermsOfService.vue")
    },
    {
        name: "eula",
        path: "/legal/eula",
        component: () => import("../../legal/pages/EndUserLicenseAgreement.vue")
    },

    // Login
    {
        name: "Login",
        path: "/login",
        component: () => import("../../auth/pages/Login.vue"),
        meta: { keepUrl: true }
    },
    {
        name: "Register",
        path: "/signup",
        component: () => import("../../auth/pages/Register.vue"),
        meta: { keepUrl: true }
    },
    {
        name: "Forgot Password",
        path: "/forgot-password",
        component: () => import("../../auth/pages/ForgotPassword.vue"),
        meta: { keepUrl: true }
    },
    {
        name: "Reset Password",
        path: "/reset-password",
        component: () => import("../../auth/pages/ResetPassword.vue"),
        meta: { keepUrl: true }
    },

    // User Portal
    {
        name: "User Portal",
        path: "/id",
        component: () => import("../../id/pages/UserPortal.vue"),
        meta: { requiresAuth: true },
        redirect: "/id/downloads",
        children: [
            {
                name: "Downloads",
                path: "downloads",
                component: () => import("../../id/pages/UserDownloads.vue")
            },
            {
                name: "Account",
                path: "account",
                component: () => import("../../id/pages/UserAccount.vue")
            },
            {
                name: "Licenses",
                path: "licenses",
                component: () => import("../../id/pages/UserLicenses.vue")
            },
            {
                name: "Billing",
                path: "billing",
                component: () => import("../../id/pages/UserBilling.vue")
            },
            {
                name: "Admin",
                path: "admin",
                component: () => import("../../id/pages/AdminUser.vue")
            }
        ]
    },

    // Checkout
    {
        name: "checkout",
        path: "/checkout/:plan/:change?",
        component: () => import("../../checkout/pages/CheckoutPage.vue")
    },

    {
        path: "/get/open-world-nature",
        redirect: "/stylized-nature"
    },
    {
        path: "/get/nature-renderer",
        redirect: "/nature-renderer"
    },
    {
        path: "/privacy-policy",
        redirect: "/legal/privacy-policy"
    },

    {
        path: "/:pathMatch(.*)*",
        component: () => import("../pages/404.vue")
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition)
    {
        if (to.hash)
        {
            return {
                el: to.hash,
                behavior: "smooth",
                top: 90
            };
        }
        if (savedPosition)
        {
            return savedPosition;
        }
        else
        {
            return {
                left: 0,
                top: 0
            };
        }
    }
});

router.beforeEach(async (to: RouteLocationNormalized, from) =>
{
    // Clean up the URL so that the campaign parameters are not left there.
    let removeUtm = false;

    if (to.query.utm_campaign)
    {
        Analytics.setCampaign(String(to.query.utm_campaign));
        removeUtm = true;
    }
    if (to.query.utm_source)
    {
        Analytics.setSource(String(to.query.utm_source));
        removeUtm = true;
    }
    if (to.query.utm_medium)
    {
        Analytics.setMedium(String(to.query.utm_medium));
        removeUtm = true;
    }

    for (const q of Object.keys(to.query))
    {
        if (q.startsWith("exp."))
        {
            const experimentId = q.substring("exp.".length);
            const experimentVariant = Number.parseInt(String(to.query[q]));
            if (experimentId && experimentVariant && !Number.isNaN(experimentVariant))
                assignVariant(experimentId, experimentVariant);

            delete to.query[q];
            removeUtm = true;
        }
    }

    if (removeUtm)
    {
        delete to.query.utm_campaign;
        delete to.query.utm_source;
        delete to.query.utm_medium;
        return to;
    }

    // Ensure the staging website is not accessed by users outside of Visual Design Cafe
    if (import.meta.env.MODE == "staging")
    {
        if (!store.getters.user)
        {
            console.log("Staging website requires a signed in user");
            if (to.path != "/login" && to.path != "/signup" && to.path != "/forgot-password")

                return "/login?url=" + to.fullPath;
        }
        else
        {
            if (!String(store.getters.user.email).endsWith("@visualdesigncafe.com")
            )
            {
                alert("You are not allowed to access this area on the staging website");
                return false;
            }
        }
    }

    // Check if the page requires a signed-in user and redirect
    // to login page if the user is not signed in.
    if (to.matched.some(record => record.meta.requiresAuth))
    {
        // If there is no user stored then we redirect to the login page
        // so that the user can sign in. The user will be redirected back
        // to the current page after signin in.
        if (!store.getters.user)
        {
            console.log(
                "Page requires auth and user is not signed in, redirecting user to login page...");
            return "/login?url=" + to.path;
        }
    }

    // Block access to admin page for anyone who does not have a visual design cafe email address.
    if (to.path.startsWith("/id/admin"))
    {
        if (!store.getters.user || !String(store.getters.user.email).endsWith("@visualdesigncafe.com"))
        {
            alert("You are not allowed to access this page");
            return false;
        }
    }

    if (from.query.url && !to.query.url)
    {
        if (to.meta.keepUrl)
        {
            to.query.url = from.query.url;
            return to;
        }
    }
});

router.afterEach((to) =>
{
    Analytics.trackPageView(to);
});

export default router;
